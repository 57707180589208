import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Container,
  Flex,
  Image,
} from '@chakra-ui/react';
import logo from '@ui/assets/snap-logo-reversed.svg';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export function GlobalError({ onReset }: { onReset: () => void }) {
  const location = useLocation();
  const initialLocationKey = useRef(location.key);

  /**
   * Reset the error boundary when the location changes. This allows users to
   * navigate "back" to a previous page after navigating to a page that triggers
   * an error.
   */
  useEffect(() => {
    if (location.key !== initialLocationKey.current) {
      onReset();
    }
  }, [onReset, location.key]);

  return (
    <Flex
      bgColor="mw.black"
      minH="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxW="container.sm" centerContent p={8}>
        <Box maxW="md">
          <Alert
            bg="white"
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            rounded={4}
            p={8}
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={2} fontSize="lg">
              Whoops!
            </AlertTitle>

            <AlertDescription mb={4}>
              Something went wrong, but we're on it.
              <br />A report has been forwarded to the team.
            </AlertDescription>

            <AlertDescription fontSize="x-small">
              Thank you for your patience. 🙏
            </AlertDescription>
          </Alert>

          <Center p={4}>
            <Image
              src={logo}
              alt="SNAP Logo"
              title="SNAP Logo"
              maxWidth="90px"
            />
          </Center>
        </Box>
      </Container>
    </Flex>
  );
}
