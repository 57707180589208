import type {
  PaginationParams,
  WithPagination,
} from '@snap-types/models/pagination';
import type {
  ConfiguratorCreatePartRequest,
  ConfiguratorSnapshotSearchQuery,
} from '@lib/requests';
import type {
  ConfiguratorCreatePartResponse,
  ConfiguratorSnapshotGetManyResponse,
  SelectOptionWithMetadata,
} from '@lib/responses';
import { siteConfigurations } from '@ui/features/configurator/data';
import type {
  Configuration,
  ConfiguratorDynamicFieldDefinition,
} from '@ui/features/configurator/types';
import type { PaginationState } from '@ui/hooks/pagination';
import type { API } from './api';

export class ConfiguratorService {
  constructor(private api: API) {}

  getConfiguration = (siteCode: string, id: string): Configuration => {
    const configuration = siteConfigurations[siteCode].find(
      (config) => id === config.id,
    );

    if (!configuration) {
      throw new Error('Configuration not found');
    }

    return configuration;
  };

  getConfigurations = (siteCode: string): Configuration[] => {
    return siteConfigurations[siteCode];
  };

  /**
   * Fetches the records for a given field then maps them to select options
   * based on it's FieldDefinition.
   */
  getFieldOptions = <
    Meta extends Record<string, unknown>,
    Params extends Record<string, unknown>,
  >({
    siteCode,
    fieldDefinition,
    query,
    pagination: { page, perPage },
    params,
  }: {
    siteCode: string;
    fieldDefinition: ConfiguratorDynamicFieldDefinition<Meta, Params>;
    query: string;
    pagination: PaginationState;
    productCode?: string;
    params?: Params;
  }): Promise<WithPagination<SelectOptionWithMetadata<string, Meta>>> => {
    if (!fieldDefinition.endpoints.list) {
      throw new Error(
        'Cannot query for field options without an endpoint specified',
      );
    }

    const path = this.api.sitePath(siteCode, fieldDefinition.endpoints.list);

    return this.api
      .get(path, {
        q: query,
        page,
        perPage,
        params,
      })
      .then((result) => {
        return {
          data: fieldDefinition.mapOptions(result.data),
          pagination: result.pagination,
        };
      });
  };

  getSingleOption = <
    Meta extends Record<string, unknown>,
    Params extends Record<string, unknown>,
  >({
    siteCode,
    id,
    fieldDefinition,
    params,
  }: {
    siteCode: string;
    id: string;
    fieldDefinition: ConfiguratorDynamicFieldDefinition<Meta, Params>;
    params?: Params;
  }): Promise<SelectOptionWithMetadata<string, Meta>> => {
    if (!fieldDefinition.endpoints.get) {
      throw new Error(
        'Cannot query for field options without an endpoint specified',
      );
    }

    const path = this.api.sitePath(
      siteCode,
      `${fieldDefinition.endpoints.get}/${encodeURIComponent(id)}`,
    );

    return this.api.get(path, params).then(fieldDefinition.mapOption);
  };

  createConfiguratedPart = async (
    siteCode: string,
    data: ConfiguratorCreatePartRequest,
  ): Promise<ConfiguratorCreatePartResponse> => {
    const path = this.api.sitePath(siteCode, '/configurator/part');

    return this.api.post(path, data);
  };

  searchConfiguratorSnapshots = (
    siteCode: string,
    search: string,
    { page, perPage }: PaginationParams,
  ) => {
    const path = this.api.sitePath(siteCode, '/configurator/snapshot/search');

    const query: ConfiguratorSnapshotSearchQuery = {
      search,
      page,
      perPage,
    };

    return this.api.get<ConfiguratorSnapshotGetManyResponse>(path, query);
  };
}

/**
 * @TODO(shawk): There are cases where this results in an infinite loop of
 * HMR invalidations causing vite to repeatedly fire off HMR update checks.
 * Bummer, dude.
 *
 * DEVELOPMENT ONLY (if HMR is enabled)
 *
 * Propagate HMR updates to importers (allows for patching the configurator
 * store with updated static configuration data).
 *
 * Ref: https://vitejs.dev/guide/api-hmr
 */
// if (import.meta.hot) {
//   import.meta.hot.accept(() => {
//     import.meta.hot?.invalidate();
//   });
// }
