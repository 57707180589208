import { Flex } from '@chakra-ui/react';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigation } from '@ui/components/layout/Navigation';
import { Router } from '@ui/config/routes';
import { ModalLayout } from '@ui/components/layout/ModalLayout';
import {
  faArrowLeft,
  faBuilding,
  faChartLine,
  faCircleCheck,
  faMoneyBill,
  faTag,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

export function AdminLayout() {
  return (
    <>
      <ModalLayout />

      <Flex direction="row">
        <Navigation.Menu>
          <Navigation.Item
            label="Back to SNAP"
            path={Router.path(Router.routes.root)}
            icon={faArrowLeft}
          />

          <Navigation.Divider />

          <Navigation.Item
            label="Users"
            path={Router.path(Router.routes.adminUsers)}
            icon={faUsers}
          />
          <Navigation.Item
            label="Sites"
            path={Router.path(Router.routes.adminSites)}
            icon={faBuilding}
          />
          <Navigation.Item
            label="Metrics"
            path={Router.path(Router.routes.adminMetrics)}
            icon={faChartLine}
          />

          <Navigation.Divider />

          <Navigation.Section
            label="Calculations"
            path={Router.path(Router.routes.adminCalcs)}
            icon={faCircleCheck}
          >
            <Navigation.Item
              label="Cost Testing"
              path={Router.path(Router.routes.adminCalcsCost)}
              icon={faMoneyBill}
            />
            <Navigation.Item
              label="Price Testing"
              path={Router.path(Router.routes.adminCalcsPrice)}
              icon={faTag}
            />
          </Navigation.Section>
        </Navigation.Menu>

        {/* @NOTE(shawk): ensure <NavigationBar /> stays visible
            while descendent lazy-loaded routes are loading */}
        <Suspense fallback={<FullScreenLoader />}>
          <Outlet />
        </Suspense>
      </Flex>
    </>
  );
}
