import { Flex } from '@chakra-ui/react';
import {
  faQuoteLeft,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';
import { WithPermission } from '@ui/components/Permission';
import { ModalLayout } from '@ui/components/layout/ModalLayout';
import { Navigation } from '@ui/components/layout/Navigation';
import { SitePageLayoutPlaceholder } from '@ui/components/layout/SiteLayout/SitePageLayoutPlaceholder';
import { Router } from '@ui/config/routes';
import { useCurrentSite } from '@ui/hooks/useCurrentSite';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export function SiteLayout() {
  const currentSite = useCurrentSite();

  return (
    <>
      <ModalLayout />

      <Flex direction="row">
        <Navigation.Menu>
          <Navigation.SiteSelector currentSite={currentSite} />

          {currentSite.quoteBuilderVersion === 'V1' && (
            <Navigation.Item
              label="Quote Queue"
              path={Router.sitePath(currentSite, Router.routes.quoteQueue)}
              icon={faQuoteLeft}
            />
          )}

          {currentSite.quoteBuilderVersion === 'V2' && (
            <Navigation.Item
              label="Quote Queue"
              path={Router.sitePath(currentSite, Router.routes.snapQuoteIndex)}
              icon={faQuoteLeft}
            />
          )}

          <WithPermission permissions={Router.routes.admin.permissions}>
            <Navigation.Divider />

            <Navigation.Item
              label="Admin"
              path={Router.path(Router.routes.admin)}
              icon={faScrewdriverWrench}
            />
          </WithPermission>
        </Navigation.Menu>

        {/* @NOTE(shawk): ensure <NavigationBar /> stays visible
            while descendent lazy-loaded routes are loading */}
        <Suspense fallback={<SitePageLayoutPlaceholder />}>
          <Outlet />
        </Suspense>
      </Flex>
    </>
  );
}
