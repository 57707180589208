import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isObject } from '@sindresorhus/is';

export type ApiResponseError = {
  status: number;
  data: {
    statusCode: string;
    error: string;
    message: string;
  };
};

/**
 * Helper for extracting error response data based on the general shape of the
 * API's `ApplicationError.toResponse()` method.
 *
 * Based on advice from https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling.
 */
export function isApiResponseError(
  error: FetchBaseQueryError | SerializedError,
): error is ApiResponseError {
  return 'status' in error && isObject(error.data) && 'message' in error.data;
}
