import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './api';
import { QuoteBuilderReducer } from '@ui/features/quote-builder/state/reducer';

export const rootReducer =
  combineSlices(api).withLazyLoadedSlices<QuoteBuilderReducer>();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Redux recommends only storing serializable data in the store
      // See https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
      // We are ignoring this so that we can align types.
      // We should be mindful of this break from the recommended practices.
      serializableCheck: false,
      // If we need to check serializablity in general, we can still ignore the API, allowing
      // us to maintain type-parity on requests, but requiring us to handle serializability
      // in the rest of the stores.

      // This is an example of what ignoring the api only would look like
      // serializableCheck: {
      //   ignoredActions: ['api/executeQuery/fulfilled'],
      //   ignoredPaths: ['api'],
      // },
    }).concat(api.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
