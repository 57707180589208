import { type EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthContext } from './context';
import { useQueryClient } from '@tanstack/react-query';
import { currentUserQuery, useGetCurrentUser } from '@ui/data/user';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const { instance: msalInstance } = useMsal();

  const query = useGetCurrentUser({
    enabled: !isLoggedOut && msalInstance.getActiveAccount() !== null,
  });

  const setAuthenticated = useCallback(
    (isAuthenticated: boolean) => {
      setIsLoggedOut(!isAuthenticated);

      if (!isAuthenticated) {
        queryClient.resetQueries({
          queryKey: currentUserQuery().queryKey,
          exact: true,
        });
      }
    },
    [queryClient],
  );

  const logout = () => {
    msalInstance.logoutRedirect({ account: msalInstance.getActiveAccount() });
  };

  useEffect(() => {
    function handleEvent(event: EventMessage) {
      /**
       * Initialize the logged out state according to the MSAL instance state.
       * We have to wait for the HANDLE_REDIRECT_END event before we check
       * whether the instance has an active account or not. If it does, then
       * the user is expected to be logged in.
       */
      if (event.eventType === EventType.HANDLE_REDIRECT_END) {
        setAuthenticated(msalInstance.getActiveAccount() !== null);
      }

      /**
       * We no longer have a valid token, so a user may need to login again.
       */
      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        setAuthenticated(false);
      }

      /**
       * The user has logged out from another tab or window.
       */
      if (event.eventType === EventType.ACCOUNT_REMOVED) {
        setAuthenticated(false);
      }
    }

    msalInstance.enableAccountStorageEvents();
    const callbackId = msalInstance.addEventCallback(handleEvent);

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, [msalInstance, setAuthenticated]);

  const value = useMemo(
    () => ({
      user: query.data ?? null,
      logout,
      isError: query.isError,
      isLoading: query.isLoading,
      isLoggedOut,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, isLoggedOut],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
