import type {
  ConfiguratorFieldState,
  ConfiguratorFunctionValidation,
} from '@ui/features/configurator/types';
import { validateNumericality } from './numericality';
import { validResult } from './result';
import { validateRunTypeIsPcsPerHr } from './run-type';
import { maxLength } from './max-length';
import type { FunctionValidatorsMap, ValidationResult } from './types';

export function validateFunction(
  fields: ConfiguratorFieldState,
  fieldKey: string,
  validation: ConfiguratorFunctionValidation,
): ValidationResult {
  const fn = functionValidators[validation.name];

  if (!fn) {
    // Ignore missing functions and return a valid result
    console.error(
      `Unable to find validator function named: ${validation.name}`,
    );

    return validResult;
  }

  return fn(fields, fieldKey, validation);
}

export const functionValidators: FunctionValidatorsMap = {
  runTypeIsPcsPerHr: validateRunTypeIsPcsPerHr,
  numericality: validateNumericality,
  maxLength: maxLength,
};
