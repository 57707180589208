import type { ResolvedOpCostsInput } from '@lib/calculations/part-history';
import type { QuoteLineCreateRequest } from '@lib/requests/quote-line';
import type { QuoteLineFullDTO } from '@prisma-types';
import type { Part } from '@prisma/client';

export const QUOTE_LINE_DESCRIPTION_MAX_LENGTH = 120;

export interface QuoteLineFull extends QuoteLineFullDTO {
  operationCosts: ResolvedOpCostsInput[] | null;
}

/**
 * The data we parse from a spreadsheet file is roughly the data required
 * to create a quote line item, but without the `quoteId` and
 * `quoteOriginalSource` (which the spreadsheet and importer won't know, but
 * the calling API or UI code will).
 */
export type ParsedQuoteLine = Omit<
  QuoteLineCreateRequest,
  'quoteId' | 'quoteOriginalSource'
>;

export type ImportedQuoteLine = ParsedQuoteLine & {
  part?: Part;
  isQuantityBreak?: boolean;
};
