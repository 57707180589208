import type {
  ConfiguratorFieldState,
  ConfiguratorMaxLengthValidation,
} from '@ui/features/configurator/types';
import { validResult } from './result';
import type { ValidationResult } from './types';
import { getStringFieldValueLength } from '@ui/features/configurator/store/helpers/get-string-field-value-length';

/**
 * Validate a max length for text based inputs
 */
export function maxLength(
  fields: ConfiguratorFieldState,
  fieldKey: string,
  { max_length }: ConfiguratorMaxLengthValidation,
): ValidationResult {
  const field = fields[fieldKey];
  const valueLength = getStringFieldValueLength(field);

  if (valueLength === null) {
    throw new Error('Field value is not a string');
  }

  if (typeof max_length === 'number' && valueLength > max_length) {
    return {
      valid: false,
      errors: [`${field.name} cannot exceed ${max_length} characters`],
    };
  }

  return validResult;
}
