import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { EnvConfig } from '@ui/config/env';
import { getToken } from '@ui/auth/get-token';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: EnvConfig.getKey('API_BASEURL'),
    prepareHeaders: async (headers) => {
      const token = await getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  endpoints: () => ({}),
});
