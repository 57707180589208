import { z } from 'zod';
import { defaultPerPage } from './types';

export const paginationSchema = z.object({
  page: z
    .number()
    .gt(0)
    .or(z.string().nonempty().pipe(z.coerce.number().gt(0)))
    .default(1),
  perPage: z
    .number()
    .gt(0)
    .or(z.string().nonempty().pipe(z.coerce.number().gt(0)))
    .default(defaultPerPage),
});

export const paginationMetadataSchema = z.object({
  page: z.number(),
  perPage: z.number(),
  totalItems: z.number(),
  totalPages: z.number(),
});
