import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Show } from '@ui/components/Show';
import type { FieldCalculatorComponentType } from '@ui/features/configurator/store/helpers/field-calculators';
import { isFieldDisabled } from '@ui/features/configurator/store/helpers/field-helpers';
import { memo } from 'react';
import type { ConfiguratorInputProps } from './types';
import { ConfiguratorMaxLengthValidation } from '@lib/models';
import { getStringFieldValueLength } from '@ui/features/configurator/store/helpers/get-string-field-value-length';

type ConfiguratorInputWrapperComponentProps = ConfiguratorInputProps & {
  children: React.ReactNode;
  hideLabel?: boolean;
  hideError?: boolean;
  calculatorComponent?: FieldCalculatorComponentType;
};

function InputWrapperComponent({
  field,
  children,
  hideLabel,
  hideError,
  minWidth = '200px',
  maxWidth = '200px',
}: ConfiguratorInputWrapperComponentProps) {
  const CalculatorComponent = field.calculatorComponent;

  const maxLengthValidator = field.validations.find(
    (v) => v.validate === 'function' && v.name === 'maxLength',
  ) as ConfiguratorMaxLengthValidation | undefined;

  const maxLength = maxLengthValidator?.max_length ?? null;
  const valueLength = getStringFieldValueLength(field);

  return (
    <FormControl
      id={field.fieldKey}
      isInvalid={field.isTouched && field.isInvalid}
      isDisabled={isFieldDisabled(field)}
      isRequired={field.isRequired}
      // Style Props
      display="flex"
      flexDirection="column"
      gap={1}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      <Show when={field.name && !hideLabel}>
        <Flex gap={2} alignItems="center">
          <FormLabel fontSize="sm" fontWeight="medium" margin={0}>
            {field.description ? (
              <Tooltip label={field.description} placement="end">
                <Text
                  textDecoration="underline"
                  textDecorationStyle="dashed"
                  textDecorationColor="mw.lightGrey"
                  display="inline"
                >
                  {field.name}
                </Text>
              </Tooltip>
            ) : (
              field.name
            )}
          </FormLabel>
          {maxLength && valueLength !== null ? (
            <Text
              fontSize="sm"
              color={valueLength > maxLength ? 'validation.error' : 'mw.grey'}
            >
              {valueLength} / {maxLength}
            </Text>
          ) : null}
        </Flex>
      </Show>

      <Flex gap={1} alignItems="center">
        {children}
        {CalculatorComponent ? <CalculatorComponent field={field} /> : null}
      </Flex>

      <Show when={field.isTouched && field.errors.length > 0 && !hideError}>
        <FormErrorMessage pl={4}>
          <ul>
            {field.errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </FormErrorMessage>
      </Show>
    </FormControl>
  );
}

/**
 * Wrapper for Configurator input components, handling errors, labels, and visibility.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorInputWrapper = memo(InputWrapperComponent);
