import type { PaginationParams } from '@snap-types/models/pagination';
import type { PartSearchQuery } from '@lib/requests/part';
import type { InventoryResponse } from '@lib/responses/inventory';
import type { PaginatedOrderLineHistoryResponse } from '@lib/responses/order-line';
import type {
  PartHistoryResponse,
  PartSearchResponse,
} from '@lib/responses/part';
import type { PaginatedQuoteHistoryResponse } from '@lib/responses/quote';
import type { API } from './api';

export class PartService {
  constructor(private api: API) {}

  searchParts = (siteCode: string, query: PartSearchQuery) => {
    const path = '/part';

    return this.api.get<PartSearchResponse>(
      this.api.sitePath(siteCode, path),
      query,
    );
  };

  getPartHistory = (
    siteCode: string,
    partId: string,
    customerId?: string | null,
  ) => {
    const path = `/part/history/${encodeURIComponent(partId)}`;

    return this.api.get<PartHistoryResponse>(
      this.api.sitePath(siteCode, path),
      customerId ? { customerId } : undefined,
    );
  };

  getPartOrderLineHistory = (
    siteCode: string,
    partId: string,
    { page, perPage }: PaginationParams,
  ) => {
    const path = `/part/history/${encodeURIComponent(partId)}/order-line`;

    return this.api.get<PaginatedOrderLineHistoryResponse>(
      this.api.sitePath(siteCode, path),
      { page, perPage },
    );
  };

  getPartQuoteHistory = (
    siteCode: string,
    partId: string,
    { page, perPage }: PaginationParams,
  ) => {
    const path = `/part/history/${encodeURIComponent(partId)}/quote`;

    return this.api.get<PaginatedQuoteHistoryResponse>(
      this.api.sitePath(siteCode, path),
      { page, perPage },
    );
  };

  getPartOptions = (siteCode: string, partId: string) => {
    const path = '/part-options';

    return this.api.get(this.api.sitePath(siteCode, path), { partId });
  };

  getInventory = (siteCode: string, partId: string) => {
    const path = '/part-inventory';

    return this.api.get<InventoryResponse>(this.api.sitePath(siteCode, path), {
      partId,
    });
  };
}
