import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import type { NavigationItem } from './types';
import { Item } from './Item';
import { useNavigationContext } from './context';

type SectionProps = NavigationItem & {
  children?: React.ReactNode;
};

export function Section({ children, label, path, icon }: SectionProps) {
  const { currentPath, isOpen } = useNavigationContext();

  const item = <Item label={label} path={path} icon={icon} />;

  const isCurrent = currentPath.startsWith(path);

  if (!children || !isOpen) {
    return item;
  }

  return (
    <Accordion
      key={isCurrent ? 'open' : 'close'}
      index={isCurrent ? [0] : undefined}
      allowToggle
      reduceMotion
      borderTopWidth={0}
      borderBottomWidth={0}
      borderStyle="none"
      width="100%"
    >
      <AccordionItem border="none">
        <AccordionButton padding={0}>
          <Box>{item}</Box>
          <Box
            flexGrow={1}
            marginLeft={3}
            display="flex"
            color="mw.white"
            opacity={0.72}
            _hover={
              !isCurrent
                ? {
                    opacity: 1,
                  }
                : undefined
            }
            cursor={isCurrent ? 'default' : undefined}
          >
            <AccordionIcon marginLeft="auto" />
          </Box>
        </AccordionButton>
        <AccordionPanel padding={0} paddingLeft={isOpen ? 4 : 0}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
