import { Flex } from '@chakra-ui/react';
import {
  faPlus,
  faQuoteLeft,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';
import { WithPermission } from '@ui/components/Permission';
import { ModalLayout } from '@ui/components/layout/ModalLayout';
import { Navigation } from '@ui/components/layout/Navigation';
import { SitePageLayoutPlaceholder } from '@ui/components/layout/SiteLayout/SitePageLayoutPlaceholder';
import { Router } from '@ui/config/routes';

export function SiteLayoutPending() {
  return (
    <>
      <ModalLayout />

      <Flex direction="row">
        <Navigation.Menu>
          <Navigation.SiteSelector isPending />

          {/* Nav items are links which can't be `disabled`, so we do what we
              can to render them inert without swapping the native element to
              something other than an anchor */}
          <Navigation.Item
            label="Quote Queue"
            path="#"
            icon={faQuoteLeft}
            linkProps={{
              opacity: 0.5,
              pointerEvents: 'none',
              onClick: (e) => {
                e.preventDefault();
              },
            }}
          />
          <Navigation.Item
            label="New Quote"
            path="#"
            icon={faPlus}
            linkProps={{
              opacity: 0.5,
              pointerEvents: 'none',
              onClick: (e) => {
                e.preventDefault();
              },
            }}
          />

          <WithPermission permissions={Router.routes.admin.permissions}>
            <Navigation.Divider />

            <Navigation.Item
              label="Admin"
              path={Router.path(Router.routes.admin)}
              icon={faScrewdriverWrench}
            />
          </WithPermission>
        </Navigation.Menu>

        <SitePageLayoutPlaceholder />
      </Flex>
    </>
  );
}
