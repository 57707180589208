import { extendTheme } from '@chakra-ui/react';

const baseMWTheme = {
  fonts: {
    body: 'system-ui',
    heading: 'Source Sans Pro, system-ui, sans-serif',
    navigationItem: 'Source Sans Pro, system-ui, sans-serif',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    body: 'Source Sans Pro, system-ui, sans-serif',
    heading: 'Source Sans Pro, serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    snap: {
      gray: {
        100: '#e6e5e5',
      },
      red: {
        600: 'hsl(8 100% 47% / 1)',
        '600/10': 'hsl(8 100% 47% / 0.1)',
        '600/20': 'hsl(8 100% 47% / 0.2)',
        700: '#A20C33',
        800: '#720925',
      },
      teal: {
        600: 'hsl(184 83.2% 25.7%)',
        '600/20': 'hsl(184 83.2% 25.7% / 0.2)',
        700: '#046068',
      },
      yellow: {
        600: 'hsl(50 100% 50%)',
        '600/20': 'hsl(50 100% 50% / 0.2)',
        700: '#ccaa00',
      },
    },
    mw: {
      yellow: '#FFD600',
      darkYellow: '#ebc603',
      black: '#001223',
      grey: '#465B65',
      darkGrey: '#273b46',
      lightGrey: '#BBBABA',
      panelGrey: 'rgb(238,240,242)',
      blue: '#00A8B5',
      darkBlue: '#007B7B',
      orange: '#F78B21',
      darkOrange: '#F36B21',
      red: '#EE3341',
      darkRed: '#A20C33',
      green: '#BFD730',
      darkGreen: '#6E9934',
      white: '#FFFFFF',
    },
    validation: {
      error: '#f22000',
      warning: '#d08700',
      info: '#00A8B5',
    },
    input: {
      icon: '#999',
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  // Base Components styles
  components: {
    Input: {
      baseStyle: {
        field: {
          bg: 'white',
          borderWidth: '1px',
        },
      },
      variants: {
        default: {
          field: {},
        },
      },
      defaultProps: {
        variant: 'default',
      },
    },
    Select: {
      variants: {
        default: {
          field: {
            bg: 'white',
            borderWidth: '1px',
          },
        },
      },
      defaultProps: {
        variant: 'default',
      },
    },
  },
};

export type Breakpoints = 'base' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const breakpoints: Record<Breakpoints, string> = {
  base: '0em', // 0px
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '96em', // 1536px
};

const theme = extendTheme({ ...baseMWTheme, ...breakpoints });

export default theme;
