import type { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from '@ui/auth/navigationClient';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { AppRoutes } from '@ui/config/routes';
import { AuthProvider } from '@ui/state/auth';
import { GlobalStateProvider } from '@ui/state/global';
import { Provider } from 'react-redux';
import { store } from './store';

type AppProps = {
  msalInstance: IPublicClientApplication;
};

export function App({ msalInstance }: AppProps) {
  // configure MSAL redirects between pages to use internal routing
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <Provider store={store}>
          <GlobalStateProvider>
            <Suspense fallback={<FullScreenLoader />}>
              <AppRoutes />
            </Suspense>
          </GlobalStateProvider>
        </Provider>
      </AuthProvider>
    </MsalProvider>
  );
}
