import type {
  ConfiguratorBlueprintState,
  ConfiguratorFieldState,
  ConfiguratorFieldType,
  ConfiguratorStoreState,
} from '@ui/features/configurator/types';

import {
  FIELD_IDS,
  STEP_KEYS,
} from '@ui/features/configurator/data/fixtures/cold-headed-fastener';

import {
  getDescriptionFieldValue,
  getMaterialFieldValue,
  getNumberFieldValue,
  getOnlyFieldByStepKey,
  getSingleChoiceFieldLabel,
  getSingleChoiceFieldMeta,
  getSingleChoiceFieldValue,
  getTextFieldValue,
  maybeGetAllFieldsByStepKey,
} from './field-helpers';

import {
  type DescriptionInputs,
  createDescription,
} from '@ui/features/configurator/calculations/description';
import { validateField } from './validate-field';

export function generateDescription({
  blueprint,
  fields: currentFields,
}: ConfiguratorStoreState) {
  const field = getOnlyFieldByStepKey(
    STEP_KEYS.description,
    FIELD_IDS.description,
    currentFields,
    blueprint,
  );
  const value = getDescriptionFieldValue(field);

  if (value.override) {
    return;
  }

  const inputs = collectInputs(blueprint, currentFields);

  field.isTouched = true;

  field.value = {
    ...value,
    value: createDescription(inputs),
  };

  validateField(currentFields, field.fieldKey);
}

function collectInputs(
  blueprint: ConfiguratorBlueprintState,
  fields: ConfiguratorFieldState,
): DescriptionInputs {
  // Fetch unique fields
  const uniqueQueries = {
    partId: [STEP_KEYS.base_fields, FIELD_IDS.customer_part_id],
    threadType: [STEP_KEYS.threading, FIELD_IDS.thread_type],
    threadDiameter: [STEP_KEYS.threading, FIELD_IDS.thread_diameter],
    threadsPerInch: [STEP_KEYS.threading, FIELD_IDS.threads_per_inch],
    shankLength: [STEP_KEYS.shank, FIELD_IDS.shank_length],
    shankLengthFractional: [STEP_KEYS.shank, FIELD_IDS.shank_length_fractional],
    headSlotStyle: [STEP_KEYS.head_style, FIELD_IDS.head_slot_style],
    headStyle: [STEP_KEYS.head_style, FIELD_IDS.head_style],
    recessStyle: [STEP_KEYS.head_style, FIELD_IDS.head_recess_style],
    shankShoulder: [STEP_KEYS.shank, FIELD_IDS.shank_shoulder],
    washerStyle: [STEP_KEYS.sems, FIELD_IDS.washer_style],
    material: [STEP_KEYS.material, FIELD_IDS.material],
  };

  const uniqueFields: Record<string, ConfiguratorFieldType> = {};

  for (const [name, [stepKey, fieldId]] of Object.entries(uniqueQueries)) {
    uniqueFields[name] = getOnlyFieldByStepKey(
      stepKey,
      fieldId,
      fields,
      blueprint,
    );
  }

  // Fetch reusable fields
  const reusableQueries = {
    pointStyles: [STEP_KEYS.pointing, FIELD_IDS.point_style],
    serrationTypes: [STEP_KEYS.serrations, FIELD_IDS.serration_type],
  };

  const reusableFields: Record<string, ConfiguratorFieldType[]> = {};

  // @TODO(cj): These fields are returned in the order the steps were originally created,
  // not the order they are currently shown in the form.
  //
  // e.g. If you have two pointing operations and reorder them, the point style fields
  // will be returned in the order of the original operations, not the new order.
  //
  // Maybe this isn't a problem, but it's definitely an inconsistency to note.
  for (const [name, [stepKey, fieldId]] of Object.entries(reusableQueries)) {
    reusableFields[name] = maybeGetAllFieldsByStepKey(
      stepKey,
      fieldId,
      fields,
      blueprint,
    );
  }

  return {
    uom: 'English',
    partId: getTextFieldValue(uniqueFields.partId, ''),
    headSlotStyle: getSingleChoiceFieldValue(uniqueFields.headSlotStyle, ''),
    headStyle: getSingleChoiceFieldValue(uniqueFields.headStyle, ''),
    recessStyle: getSingleChoiceFieldValue(uniqueFields.recessStyle, ''),
    shankLength: getNumberFieldValue(uniqueFields.shankLength, 0),
    shankLengthFractional: getSingleChoiceFieldValue(
      uniqueFields.shankLengthFractional,
      '',
    ),
    shankStyle: getSingleChoiceFieldValue(uniqueFields.shankShoulder, ''),
    threadsPerInch: getSingleChoiceFieldLabel(uniqueFields.threadsPerInch, ''),
    threadDiameter: getSingleChoiceFieldMeta(uniqueFields.threadDiameter, {
      threadSize: '',
    }).threadSize,
    threadType: getSingleChoiceFieldLabel(uniqueFields.threadType, ''),
    washerStyle: getSingleChoiceFieldLabel(uniqueFields.washerStyle, ''),
    material: getMaterialFieldValue(uniqueFields.material, ''),
    pointStyles: reusableFields.pointStyles.map((field) =>
      getSingleChoiceFieldValue(field, ''),
    ),
    serrationTypes: reusableFields.serrationTypes.map((field) =>
      getSingleChoiceFieldValue(field, ''),
    ),

    // @TODO(cj): Figure out what these are and where they come from
    torxDesc: '',
    torxDescRec: '',

    // @TODO(cj): Figure out where this comes from
    materialGrade: '',
  };
}
