import { loginRequest, msalInstance } from '@ui/config/authConfig';

export async function getToken() {
  if (msalInstance) {
    const account = msalInstance.getActiveAccount();
    if (account) {
      return (
        await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account,
        })
      ).accessToken;
    }
  }
}
