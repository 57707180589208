import { UnreachableCaseError } from '@lib/validation';
import type { AvailableSite } from '@prisma-types';
import type { DisplayMagnitude } from '@prisma/client';
import { toggleFieldEditableAction } from '@ui/features/configurator/store/actions/toggle-field-editable';
import { serializeConfiguratorSnapshot } from '@ui/features/configurator/store/helpers/serialize-configurator-snapshot';
import type {
  Configuration,
  ConfiguratorStore,
  ConfiguratorStoreAction,
} from '@ui/features/configurator/types';
import { setAutoFreeze } from 'immer';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import type { DevtoolsOptions } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  addOperationAction,
  createOperationAction,
} from './actions/add-operation';
import { moveOperationAction } from './actions/move-operation';
import { removeOperationAction } from './actions/remove-operation';
import { setFieldErrorAction } from './actions/set-field-error';
import { updateFieldAction } from './actions/update-field';
import { calculateCosts } from './helpers/calculate-costs';
import { generateDescription } from './helpers/generate-description';
import { initializeConfiguratorStore } from './helpers/initialize-configurator-store';
import { resolveDependencies } from './helpers/resolve-dependencies';
import { serializeConfigurationState } from './helpers/serialize';
import { checkFormValidity } from './helpers/check-form-validity';

setAutoFreeze(false);

// recommended: install Redux devtools extension for your browser
// https://github.com/pmndrs/zustand?tab=readme-ov-file#redux-devtools
const storeDevToolsOptions: DevtoolsOptions = {
  enabled: import.meta.env.DEV,
  anonymousActionType: 'unknown',
};

// note: the redux devtool expects to log one action at a time.
// the stringified array is only necessary with this custom array dispatch
const zustandActionsToDevToolsLog = (actions: ConfiguratorStoreAction[]) => {
  return actions.length === 1
    ? actions[0]
    : actions.map((a) => `${a.type} ${JSON.stringify(a.payload)}`).join('----');
};

/**
 * Creates a store for a configurator provider.
 * We are using the immer middleware for managing complex and immutable state
 *
 * @docs https://docs.pmnd.rs/zustand/integrations/immer-middleware
 */
export function createConfiguratorStore(
  site: AvailableSite,
  incomingConfiguration: Configuration,
  quantityDisplayMagnitude: DisplayMagnitude,
) {
  const { configuration, fields, blueprint, costs } =
    initializeConfiguratorStore(incomingConfiguration);

  const configuratorStore = createStore<ConfiguratorStore>()(
    devtools(
      immer((set, get) => ({
        site,
        configuration,
        fields,
        blueprint,
        costs,
        quantityDisplayMagnitude,

        dispatch: (actionOrActions) => {
          const actions = Array.isArray(actionOrActions)
            ? actionOrActions
            : [actionOrActions];
          const devToolsLoggedAction = zustandActionsToDevToolsLog(actions);
          return set(
            (state) => {
              const previousState = get();

              let forceCalculation = false;

              for (const action of actions) {
                switch (action.type) {
                  case 'addOperation':
                    addOperationAction(state, action.payload.operation);
                    break;

                  case 'createOperation':
                    createOperationAction(state, action.payload.operation);
                    break;

                  case 'moveOperation':
                    moveOperationAction(
                      state,
                      action.payload.stepKey,
                      action.payload.index,
                    );
                    break;

                  case 'removeOperation':
                    removeOperationAction(state, action.payload.stepId);
                    forceCalculation = true;
                    break;

                  case 'updateField':
                    updateFieldAction(
                      state,
                      action.payload.fieldKey,
                      action.payload.value,
                    );
                    break;

                  case 'setFieldError':
                    setFieldErrorAction(
                      state,
                      action.payload.fieldKey,
                      action.payload.error,
                    );
                    break;

                  case 'toggleFieldEditable':
                    toggleFieldEditableAction(
                      state,
                      action.payload.fieldKey,
                      action.payload.isEditable,
                    );
                    break;

                  default: {
                    throw new UnreachableCaseError(action);
                  }
                }
              }

              resolveDependencies(state, previousState.fields);
              calculateCosts(state, previousState.fields, forceCalculation);
              generateDescription(state);
            },
            undefined,
            devToolsLoggedAction,
          );
        },

        checkFormValidity: () => {
          return checkFormValidity(get());
        },

        serialize: () => {
          return serializeConfigurationState(get());
        },

        saveConfiguratorSnapshot: () => {
          const { blueprint, fields } = get();
          const savedPart = serializeConfiguratorSnapshot(blueprint, fields);
          return savedPart;
        },
      })),
      storeDevToolsOptions,
    ),
  );

  return configuratorStore;
}
