import { useAuth } from '@ui/state/auth';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { QueryError } from '@ui/components/query';

export function WithCurrentUser({ children }: { children?: React.ReactNode }) {
  const { user, isLoading, isError } = useAuth();

  if (isLoading) {
    return <FullScreenLoader loadingText="loading user data" />;
  }

  if (!user || isError) {
    return <QueryError title="Error getting user information" />;
  }

  return <>{children}</>;
}
