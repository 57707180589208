import { ConfiguratorStoreState } from '../../types';

/**
 * Check if the form is valid, respecting hidden and disabled fields
 *
 * @param store - The configurator store
 * @returns true if the form is valid, false otherwise
 */
export function checkFormValidity(
  store: Pick<ConfiguratorStoreState, 'fields'>,
): boolean {
  const { fields } = store;

  const hasInvalidFields = Object.values(fields).some(
    (field) =>
      field.isInvalid &&
      !(
        field.isHidden ||
        field.isAlwaysHidden ||
        field.isDisabledFromDependency ||
        field.isDisabledProgrammatically
      ),
  );

  return !hasInvalidFields;
}
