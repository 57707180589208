import type { UserWithSettingsResponse } from '@lib/responses';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { userService } from '@ui/services';

export const currentUserQuery = () => {
  return {
    queryKey: ['currentUser'],
    queryFn: () => userService.getUser(),
  } as const;
};

export function useGetCurrentUser(
  options?: Omit<
    UseQueryOptions<UserWithSettingsResponse>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    ...currentUserQuery(),
    ...options,
    placeholderData: keepPreviousData,
  });
}
