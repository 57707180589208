import { Box } from '@chakra-ui/react';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { PageHeader } from '@ui/components/layout/PageHeader';

export function SitePageLayoutPlaceholder() {
  return (
    <Box width={'100%'}>
      <PageHeader
        title="Loading site..."
        subtitle="Hang tight"
        bgColor="mw.grey"
        textColor="mw.white"
      />
      <FullScreenLoader loadingText="Loading site..." />
    </Box>
  );
}
