import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { routesConfig } from './config';
import { ProtectedRoute } from '@ui/components/ProtectedRoute';
import { QuoteRedirector } from '@ui/components/QuoteRedirector';
import { HomeLayout } from '@ui/components/layout/HomeLayout';
import { SiteLayout } from '@ui/components/layout/SiteLayout';
import { AdminLayout } from '@ui/components/layout/AdminLayout';
import { UserLayout } from '@ui/components/layout/UserLayout';
import { WithCurrentSite } from '@ui/components/WithCurrentSite';
import { WithPermission } from '@ui/components/Permission';

const Home = React.lazy(() => import('../../pages/Home'));
const QuoteQueue = React.lazy(() => import('@ui/pages/QuoteQueue'));
const ConfigsPanel = React.lazy(
  () => import('@ui/pages/Admin/Config/ConfigsPanel'),
);
const AdminMetrics = React.lazy(
  () => import('@ui/pages/Admin/Metrics/AdminMetrics'),
);
const AdminSiteIndex = React.lazy(
  () => import('@ui/pages/Admin/Site/AdminSiteIndex'),
);
const AdminUserIndex = React.lazy(
  () => import('@ui/pages/Admin/User/AdminUserIndex'),
);
const AdminCalcsCostIndex = React.lazy(
  () => import('@ui/pages/Admin/Calcs/CostIndex'),
);
const AdminCalcsPriceIndex = React.lazy(
  () => import('@ui/pages/Admin/Calcs/PriceIndex'),
);
const LoggedOut = React.lazy(() => import('@ui/pages/LoggedOut'));
const NotFound = React.lazy(() => import('@ui/pages/NotFound'));
const Profile = React.lazy(() => import('@ui/pages/Profile'));
const QuoteFeedback = React.lazy(
  () => import('@ui/pages/QuoteFeedback/QuoteFeedback'),
);
const QuoteNew = React.lazy(() => import('@ui/pages/QuoteNew'));
const QuoteEdit = React.lazy(() => import('@ui/pages/QuoteEdit'));
const QuoteView = React.lazy(() => import('@ui/pages/QuoteView/QuoteView'));
const SiteShow = React.lazy(() => import('@ui/pages/SiteShow'));
const SnapQuoteNew = React.lazy(() => import('@ui/pages/SnapQuoteNew'));
const SnapQuoteEdit = React.lazy(() => import('@ui/pages/SnapQuoteEdit'));
const SnapQuoteIndex = React.lazy(() => import('@ui/pages/SnapQuoteIndex'));

export function AppRoutes() {
  const {
    quoteQueue,
    quoteNew,
    quoteView,
    quoteEdit,
    quoteFeedback,
    snapQuoteIndex,
    snapQuoteNew,
    snapQuoteEdit,
    userProfile,
    admin,
    adminSiteConfig,
    adminUsers,
    adminSites,
    adminMetrics,
    adminCalcs,
    adminCalcsCost,
    adminCalcsPrice,
  } = routesConfig;

  return (
    <Routes>
      {/* Authenticated routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        }
      >
        {/* Homepage */}
        <Route element={<HomeLayout />}>
          <Route index element={<Home />} />
        </Route>

        {/* Quote redirects */}
        <Route path="/quotes" element={<QuoteRedirector action="index" />} />
        <Route path="/quote/new" element={<QuoteRedirector action="new" />} />
        <Route path="/quote/:id" element={<QuoteRedirector action="edit" />} />
        <Route
          path="/quote/view/:id"
          element={<QuoteRedirector action="view" />}
        />
        <Route
          path="/quote/:id/feedback"
          element={<QuoteRedirector action="feedback" />}
        />

        {/* User routes */}
        <Route path={userProfile.path} element={<UserLayout />}>
          <Route index element={<Profile />} />
        </Route>

        {/* Site routes */}
        <Route
          path="/sites/:siteCode"
          element={
            <WithCurrentSite>
              <SiteLayout />
            </WithCurrentSite>
          }
        >
          <Route index element={<SiteShow />} />

          {/* Quotes */}
          <Route path={quoteQueue.path} element={<QuoteQueue />} />
          <Route path={quoteNew.path} element={<QuoteNew />} />
          <Route path={quoteEdit.path} element={<QuoteEdit />} />
          <Route path={quoteView.path} element={<QuoteView />} />
          <Route path={quoteFeedback.path} element={<QuoteFeedback />} />

          {/* Snap Quotes */}
          <Route path={snapQuoteIndex.path} element={<SnapQuoteIndex />} />
          <Route path={snapQuoteNew.path} element={<SnapQuoteNew />} />
          <Route path={snapQuoteEdit.path} element={<SnapQuoteEdit />} />
        </Route>

        {/* Admin routes */}
        <Route
          path="/admin"
          element={
            <WithPermission permissions={admin.permissions} showUnauthorized>
              <AdminLayout />
            </WithPermission>
          }
        >
          <Route index element={<Navigate to={adminUsers.path} replace />} />

          <Route
            path={adminUsers.path}
            element={
              <WithPermission
                permissions={adminUsers.permissions}
                showUnauthorized
              >
                <AdminUserIndex />
              </WithPermission>
            }
          />

          <Route
            path={adminSites.path}
            element={
              <WithPermission
                permissions={adminSites.permissions}
                showUnauthorized
              >
                <AdminSiteIndex />
              </WithPermission>
            }
          />

          <Route
            path={adminSiteConfig.path}
            element={
              <WithPermission
                permissions={adminSiteConfig.permissions}
                showUnauthorized
              >
                <WithCurrentSite checkAccess={false}>
                  <ConfigsPanel />
                </WithCurrentSite>
              </WithPermission>
            }
          />

          <Route
            path={adminMetrics.path}
            element={
              <WithPermission
                permissions={adminMetrics.permissions}
                showUnauthorized
              >
                <AdminMetrics />
              </WithPermission>
            }
          />

          <Route path={adminCalcs.path}>
            <Route index element={<Navigate to={adminCalcsCost.path} />} />
            <Route
              path={adminCalcsCost.path}
              element={<AdminCalcsCostIndex />}
            />
            <Route
              path={adminCalcsPrice.path}
              element={<AdminCalcsPriceIndex />}
            />
          </Route>
        </Route>
      </Route>

      {/* User auth routes */}
      <Route path="/auth/redirect" element={null} />
      <Route path="/logout/redirect" element={<LoggedOut />} />

      {/* Catch-all */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
