import { faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import type { CustomIconProps } from './types';

/**
 * Renders a sticky note icon with an optional badge.
 *
 * @see Docs https://fontawesome.com/icons/note-sticky?f=classic&s=regular
 */
export const StickyNoteIcon: React.FC<CustomIconProps> = (props) => {
  return <Icon icon={faNoteSticky} {...props} />;
};
