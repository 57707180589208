import { CreateSnapQuoteRequest } from '@snap-types/actions/create-snap-quote';
import type { SnapQuote } from '@snap-types/models/snap-quote';
import type { SnapQuotesIndex } from '@snap-types/actions/index-snap-quotes';
import type { API } from './api';
import { deserializeSuperjson, type SuperJSONResult } from '@lib/superjson';

export class SnapQuoteService {
  constructor(private api: API) {}

  indexSnapQuotes = async (siteCode: string): Promise<SnapQuotesIndex> => {
    const response = await this.api.get<SuperJSONResult>(
      this.api.sitePath(siteCode, '/snap-quotes'),
    );

    return deserializeSuperjson(response);
  };

  createSnapQuote = async (
    siteCode: string,
    data: CreateSnapQuoteRequest,
  ): Promise<SnapQuote> => {
    const response = await this.api.post<SuperJSONResult>(
      this.api.sitePath(siteCode, '/snap-quotes'),
      data,
    );

    return deserializeSuperjson(response);
  };

  deleteSnapQuote = async (
    siteCode: string,
    uuid: string,
  ): Promise<SnapQuote> => {
    const response = await this.api.delete<SuperJSONResult>(
      this.api.sitePath(siteCode, `/snap-quotes/${uuid}`),
    );

    return deserializeSuperjson(response);
  };
}
