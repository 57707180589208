import { SuperJSON, type SuperJSONResult } from 'superjson';
import { Decimal } from 'decimal.js';
export type { SuperJSONResult } from 'superjson';

SuperJSON.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Decimal(v),
  },
  'decimal.js',
);

export function deserializeSuperjson<T>(data: SuperJSONResult): T {
  return SuperJSON.deserialize<T>(data);
}

export function serializeSuperjson<T>(data: T): SuperJSONResult {
  return SuperJSON.serialize(data);
}
