import { PermActions } from '@lib/permissions';
import { ResourceEnum } from '@prisma/client';

import type { RoutesConfig } from './types';

export const routesConfig = {
  root: {
    label: 'Home',
    path: '/',
  },

  // Quotes
  quoteQueue: {
    label: 'Quote Queue',
    path: '/sites/:siteCode/quotes',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteNew: {
    label: 'Create Quote',
    path: '/sites/:siteCode/quotes/new',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.WRITE] },
  },
  quoteEdit: {
    label: 'Edit Quote',
    path: '/sites/:siteCode/quotes/:id',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteView: {
    label: 'View Quote',
    path: '/sites/:siteCode/quotes/:id/view',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteFeedback: {
    label: 'Quote Feedback',
    path: '/sites/:siteCode/quotes/:id/feedback',
  },

  // Snap Quotes
  snapQuoteIndex: {
    label: 'SNAP Quotes Queue',
    path: '/sites/:siteCode/snap-quotes',
  },
  snapQuoteNew: {
    label: 'New SNAP Quote',
    path: '/sites/:siteCode/snap-quotes/new',
  },
  snapQuoteEdit: {
    label: 'Edit Quote',
    path: '/sites/:siteCode/snap-quotes/:id',
  },

  // Admin
  admin: {
    label: 'Admin',
    path: '/admin',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminUsers: {
    label: 'Users',
    path: '/admin/users',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_USERS]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminSites: {
    label: 'Sites',
    path: '/admin/sites',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_SITE]: [PermActions.READ],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminSiteConfig: {
    label: 'Site Config',
    path: '/admin/sites/:siteCode/config',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_SITE]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminMetrics: {
    label: 'Metrics',
    path: '/admin/metrics',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_METRICS]: [PermActions.READ],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminCalcs: {
    label: 'Cost Calculations',
    path: '/admin/calcs',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
    },
  },
  adminCalcsCost: {
    label: 'Cost Calculations Test Page',
    path: '/admin/calcs/cost',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
    },
  },
  adminCalcsPrice: {
    label: 'Price Calculations Test Page',
    path: '/admin/calcs/price',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
    },
  },

  // User
  userProfile: {
    label: 'User Profile',
    path: '/current-user',
  },
} as const satisfies RoutesConfig;
