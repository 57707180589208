import type { ConfiguratorFieldType } from '@ui/features/configurator/types';

/**
 * Extracts the length of the string value from text, textarea and description fields.
 *
 * For other field types, it returns null as they don't have applicable string values
 */
export function getStringFieldValueLength(
  field: ConfiguratorFieldType,
): number | null {
  let value = '';

  switch (field.inputType) {
    case 'text':
    case 'textarea':
      value = field.value ?? '';
      break;

    case 'description':
      value = field.value?.value ?? '';
      break;

    default:
      return null;
  }

  return value.length;
}
