import type { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import type { PaginationParams } from '@snap-types/models/pagination';
import type { PreferredServiceVendor } from '@lib/models/configurator/static-part-metadata/preferred-service-vendors';
import type { UnitOfMeasure } from '@lib/models/configurator/unit-of-measure';
import type { SelectOptionWithMetadata } from '@lib/responses';
import { calculatePreferredVendorPricePerUnit } from '@lib/util';
import { configuratorServiceSchema } from '@lib/validation';
import type { ConfiguratorVendorOptionMeta } from '@ui/features/configurator/data/field-definitions/vendor';
import { FIELD_IDS } from '@ui/features/configurator/data/fixtures/cold-headed-fastener';
import {
  createFieldKey,
  findStaticOption,
  updateField,
} from '@ui/features/configurator/store';
import { toggleFieldEditable } from '@ui/features/configurator/store/actions/toggle-field-editable';
import type {
  ConfiguratorDynamicFieldDefinition,
  ConfiguratorFieldState,
  ConfiguratorFieldType,
  ConfiguratorNumberFieldType,
  ConfiguratorSingleChoiceFieldType,
  ConfiguratorSingleChoiceValue,
  ConfiguratorStoreAction,
} from '@ui/features/configurator/types';
import { configuratorService } from '@ui/services';

export type ConfiguratorServiceOptionMeta = {
  id: string;
  description: string | null;
  vendorId: string | null;
  run: number | null;
  runType: VisOpRunRateType | null;
};

export const serviceFieldDefinition: ConfiguratorDynamicFieldDefinition<ConfiguratorServiceOptionMeta> =
  {
    endpoints: {
      list: '/configurator/services',
    },

    mapOption: (data) => {
      const service = configuratorServiceSchema.parse(data);

      return {
        label: service.id,
        value: service.id,
        description: service.description,
        meta: {
          description: service.description,
          id: service.id,
          run: service.run,
          runType: service.run_type,
          vendorId: service.vendor_id,
        },
      };
    },

    mapOptions: (resources) => {
      return resources.map(serviceFieldDefinition.mapOption);
    },

    fetchOne: () => {
      throw new Error('Not implemented');
    },

    search: ({
      siteCode,
      query,
      pagination,
    }: {
      siteCode: string;
      query: string;
      pagination: PaginationParams;
    }) => {
      return configuratorService.getFieldOptions({
        siteCode,
        fieldDefinition: serviceFieldDefinition,
        query,
        pagination,
      });
    },
  };

/**
 * Builds a list of field updates based on a change in the selected Operation
 * Type.
 */
export function buildServiceFieldUpdates(
  fields: ConfiguratorFieldState,
  useStandardPricing: boolean,
  vendorField: ConfiguratorSingleChoiceFieldType<ConfiguratorVendorOptionMeta>,
  unitOfMeasureField: ConfiguratorSingleChoiceFieldType,
  pricePerUnitOfMeasureField: ConfiguratorNumberFieldType,
  vendorOption: SelectOptionWithMetadata<
    string,
    ConfiguratorVendorOptionMeta
  > | null,
  preferredVendor: PreferredServiceVendor | null,
  totalMaterialQuantityPer: number,
): ConfiguratorStoreAction[] {
  const minChargeFieldKey = createFieldKey(
    vendorField.stepId,
    FIELD_IDS.minimum_charge,
  );
  const baseChargeFieldKey = createFieldKey(
    vendorField.stepId,
    FIELD_IDS.base_charge,
  );
  const pricePerUnitOfMeasureFieldKey = createFieldKey(
    vendorField.stepId,
    FIELD_IDS.price_per_unit_of_measure,
  );

  const actions: ConfiguratorStoreAction[] = [
    updateField(vendorField.fieldKey, vendorOption),
  ];

  /**
   * If "Use Standard Pricing" is unchecked, set the associated service cost
   * fields to be editable so a user can provide them.
   */
  if (!useStandardPricing) {
    actions.push(
      toggleFieldEditable(unitOfMeasureField.fieldKey, true),
      toggleFieldEditable(minChargeFieldKey, true),
      toggleFieldEditable(baseChargeFieldKey, true),
      toggleFieldEditable(pricePerUnitOfMeasureFieldKey, true),
    );

    return actions;
  }

  /**
   * Otherwise, assuming "Use Standard Pricing" is checked. If we found a
   * Preferred Vendor for the selected Service, set the cost fields if they
   * have an associated value and make them not editable, otherwise reset
   * them and make them editable.
   */
  if (
    preferredVendor?.minCost !== undefined &&
    preferredVendor.minCost !== 'NQ'
  ) {
    actions.push(
      updateField(minChargeFieldKey, preferredVendor.minCost),
      toggleFieldEditable(minChargeFieldKey, false),
    );
  } else {
    actions.push(
      updateField(minChargeFieldKey, null),
      toggleFieldEditable(minChargeFieldKey, true),
    );
  }

  if (
    preferredVendor?.baseCost !== undefined &&
    preferredVendor.baseCost !== 'NQ'
  ) {
    actions.push(
      updateField(baseChargeFieldKey, preferredVendor.baseCost),
      toggleFieldEditable(baseChargeFieldKey, false),
    );
  } else {
    actions.push(
      updateField(baseChargeFieldKey, null),
      toggleFieldEditable(baseChargeFieldKey, true),
    );
  }

  return actions.concat(
    buildServicePricePerUnitUpdates(
      fields,
      vendorField,
      unitOfMeasureField,
      pricePerUnitOfMeasureField,
      preferredVendor,
      totalMaterialQuantityPer,
      { updateUnitOfMeasure: true },
    ),
  );
}

type BuildServicePricePerUnitUpdatesOptions = { updateUnitOfMeasure: boolean };

export function buildServicePricePerUnitUpdates(
  fields: ConfiguratorFieldState,
  vendorField: ConfiguratorSingleChoiceFieldType<ConfiguratorVendorOptionMeta>,
  unitOfMeasureField: ConfiguratorSingleChoiceFieldType,
  pricePerUnitOfMeasureField: ConfiguratorNumberFieldType,
  preferredVendor: PreferredServiceVendor | null,
  totalMaterialQuantityPer: number,
  { updateUnitOfMeasure }: BuildServicePricePerUnitUpdatesOptions,
) {
  const pricePerUnitFieldKey = createFieldKey(
    vendorField.stepId,
    FIELD_IDS.price_per_unit,
  );

  const actions: ConfiguratorStoreAction[] = [];

  if (updateUnitOfMeasure) {
    /**
     * Update the Unit of Measure field with the Preferred Vendor's UoM and make
     * it not editable.
     */
    const selectedUnitOfMeasure = findStaticOption(
      unitOfMeasureField,
      fields,
      preferredVendor?.uom,
    );

    actions.push(
      updateField(unitOfMeasureField.fieldKey, selectedUnitOfMeasure),
    );

    /**
     * If Unit of Measure is populated from the Preferred Service vendor, it
     * should not be editable.
     */
    if (selectedUnitOfMeasure) {
      actions.push(toggleFieldEditable(unitOfMeasureField.fieldKey, false));
    }
  }

  /**
   * If the preferred vendor specifies a price per unit (`costPerUom`),
   * disallow changing the uom and update the price per unit with the value
   * calculated from the vendor's `costPerUom`.
   */
  if (
    preferredVendor?.costPerUom !== undefined &&
    preferredVendor.costPerUom !== 'NQ'
  ) {
    actions.push(
      updateField(
        pricePerUnitOfMeasureField.fieldKey,
        preferredVendor.costPerUom,
      ),
      updateField(
        pricePerUnitFieldKey,
        calculatePreferredVendorPricePerUnit(
          totalMaterialQuantityPer,
          preferredVendor.uom,
          preferredVendor.costPerUom,
        ),
      ),

      toggleFieldEditable(unitOfMeasureField.fieldKey, false),
      toggleFieldEditable(pricePerUnitOfMeasureField.fieldKey, false),
    );

    // return early with just the actions above
    return actions;
  }

  /**
   * As long as UoM and Price Per UoM are defined, update the Price Per Unit
   */
  if (unitOfMeasureField.value && pricePerUnitOfMeasureField.value) {
    actions.push(
      updateField(
        pricePerUnitFieldKey,
        calculatePreferredVendorPricePerUnit(
          totalMaterialQuantityPer,
          unitOfMeasureField.value?.value as UnitOfMeasure,
          pricePerUnitOfMeasureField.value,
        ),
      ),
    );
  }

  actions.push(
    toggleFieldEditable(unitOfMeasureField.fieldKey, true),
    toggleFieldEditable(pricePerUnitOfMeasureField.fieldKey, true),
  );

  return actions;
}

export function getServiceFieldValue(field: ConfiguratorFieldType) {
  if (field.inputType !== 'service') {
    throw new Error(`Invalid service field with key ${field.fieldKey}`);
  }

  return field.value as ConfiguratorSingleChoiceValue<ConfiguratorServiceOptionMeta>;
}
