import { type OriginalSource, type Prisma, QuoteStatus } from '@prisma/client';
import { z } from 'zod';
import { type SortDirection, type SortQuery, _SORT_DIRECTIONS } from '.';
import { paginationSchema } from '@snap-types/models/pagination';
import type { CustomerHeaderResponse } from '../responses';
import type { SerializePrisma, TupleElements } from '../util';
import type { QuoteLineCreateRequest } from './quote-line';

export interface QuoteCreateRequest {
  siteCode: string;
  customerId: string | null;
  customerOriginalSource: OriginalSource | null;
  marketCode: string | null;
  discountCode: string | null;
}

export interface QuoteUpdateRequest
  extends Partial<
    SerializePrisma<
      Omit<
        Prisma.QuoteCreateManyInput,
        'createdAt' | 'updatedAt' | 'customerHeader' | 'lineItems'
      >
    >
  > {
  customerHeader: CustomerHeaderResponse | null;
  lineItems?: QuoteLineCreateRequest[];
}

const _QUOTE_SORT_FIELDS = [
  'quoteId',
  'createdBy',
  'createdAt',
  'updatedAt',
  'customerId',
  'siteId',
  'status',
  'customerName',
] as const;

export const QUOTE_SORT_FIELDS = new Set(_QUOTE_SORT_FIELDS);

export type QuoteSortRequestFields = TupleElements<typeof _QUOTE_SORT_FIELDS>;

export type QuoteSortQuery = SortQuery<QuoteSortRequestFields>;
export const QuoteSortQuery = z
  .string()
  .superRefine((val, ctx): val is QuoteSortQuery => {
    const [field, direction] = val.split(':');
    // test for valid sort field
    if (!QUOTE_SORT_FIELDS.has(field as QuoteSortRequestFields)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: `invalid sort field, must be one of: ${_QUOTE_SORT_FIELDS.join(
          ', ',
        )}`,
      });
      return z.NEVER;
    }
    // test for valid sort direction
    if (!_SORT_DIRECTIONS.includes(direction as SortDirection)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: `invalid sort direction, must be one of: ${_SORT_DIRECTIONS.join(
          ', ',
        )}`,
      });
      return z.NEVER;
    }

    return true;
  });

export const QuoteGetManyQuery = z
  .object({ sort: z.array(QuoteSortQuery).default([]) })
  .merge(paginationSchema);

export type QuoteGetManyQuery = z.infer<typeof QuoteGetManyQuery>;

export const QuoteSearchQuery = QuoteGetManyQuery.extend({
  search: z.string().optional(),
  siteCode: z.string(),
});
export type QuoteSearchQuery = z.infer<typeof QuoteSearchQuery>;

export type QuoteFeedbackRequest = {
  data: {
    id: string;
    reasonTypeId: string | null;
    comments?: string | null;
  }[];
};

export const QuoteHistoryFilterQuery = z.object({
  statusFilter: z.array(z.nativeEnum(QuoteStatus)).optional(),
  orderBy: z.enum(['startDate', 'createdAt']).optional(),
});

export type QuoteHistoryFilterQuery = z.infer<typeof QuoteHistoryFilterQuery>;

export const QuoteHistoryQuery =
  QuoteHistoryFilterQuery.merge(paginationSchema);

export type QuoteHistoryQuery = z.infer<typeof QuoteHistoryQuery>;
